<template>
  <div>
    <header-image class="header-height mb-0 mx-0">
      <img src="@/assets/img/use-guide/header-use-guide.jpg" alt="Header picture" class="relative rounded-0"
        width="auto" height="auto" />
      <h1 class="header-text">{{ $t('useGuide', [appName]) }}</h1>
    </header-image>

    <div class="px-2 pb-4 mb-4 mb-md-5 bg-white">
      <section id="youtube-video" class="container-video-youtube">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/WexOiH5fo4A" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </section>

      <div class="col-md-9 col-xl-8 col-xxl-7 mx-md-auto">
        <section class="container-text my-md-5 px-md-5">
          <p>
            {{ $t('useGuidePage.description.normal_font') }}
            <span class="bold">
              {{ $t('useGuidePage.description.bold_font') }}
            </span>
          </p>
        </section>

        <section class="bg-gray container-index col-md-7 mx-md-auto py-2">
          <h2 class="ml-3 pt-3 pb-4">{{ $t('index') }}</h2>
          <ol class="ml-md-4 pb-4">
            <li v-for="(itemIndex, index) in itemsIndex" class="itemIndex" :key="index">
              <a :href="`#${itemIndex}`" class="text-decoration-none cursor-pointer">{{
                $t(`useGuidePage.index.${itemIndex}`) }}</a>
            </li>
          </ol>
        </section>

        <hr class="my-5 border border-light-subtle">

        <section :id="elementGuide" v-for="(elementGuide, index) in Object.keys(stepsGuide)"
          class="mb-4 pb-2" :key="index">
          <div :class="`layout-${elementGuide}`">
            <section v-if="stepsGuide[elementGuide].includes('title')" class="order-1 title">
              <div class="container-icon-steps-guide ml-auto">
                <img v-if="icons[elementGuide]" :src="require(`@/assets/img/icons/${icons[elementGuide]}.png`)"
                  alt="icon">
              </div>

              <h3 class="title-steps-guide mb-4 mb-md-2">
                0{{ index + 1 }}. {{ $t(`useGuidePage.stepsGuide.${elementGuide}.title`) }}
              </h3>
            </section>

            <section
              class="description container-text mt-3 mt-md-0"
              :class="[
                elementsWithFirstImage.includes(elementGuide)
                  ? 'order-3'
                  : `order-2`
              ]"
            >
              <div class="description-container mb-2">
                <p class="my-2">{{ $t(`useGuidePage.stepsGuide.${elementGuide}.description`) }}</p>
                <ul class="my-4 pl-2" v-if="stepsGuide[elementGuide].includes('points')">
                  <li v-for="itemPoint in points[elementGuide]" class="mb-2 points-list" :key="itemPoint">
                    <p>
                      {{ $t(`useGuidePage.stepsGuide.${elementGuide}.points.${itemPoint}`) }}
                    </p>
                  </li>
                </ul>
              </div>
            </section>

            <section v-if="stepsGuide[elementGuide].includes('image')" class="background-image col mt-4 image" :class="`
                background-${elementGuide}
                order-${elementsWithFirstImage.includes(elementGuide) ? 2 : 3}
              `">
              <img v-for="image in images[elementGuide]" :src="require(`@/assets/img/use-guide/${image}.png`)"
                alt="screenshot step guide" :key="image" :class="imagesClasses[elementGuide]">
            </section>

            <section v-if="elementGuide === 'servicesAndCategories'" class="d-none d-md-flex col rest-image">
              <img v-for="image in images[elementGuide].filter((img, index) => index !== 0)"
                :src="require(`@/assets/img/use-guide/${image}.png`)" alt="screenshot step guide" :key="image">

            </section>

            <img v-if="elementGuide === 'servicesAndCategories'"
              class="d-none d-md-block background-categories-horizontal" src="@/assets/img/shared/fondo4H.png">
          </div>

          <hr v-if="index + 1 !== Object.keys(stepsGuide).length" class="my-5 border border-light-subtle order-4">
        </section>

        <section class="py-5 mx-auto  my-5">
          <p class="text-center mb-4">{{ $t('useGuidePage.cta_button.title') }}</p>

          <div class="d-flex justify-content-center">
            <base-button type="success" class="block col col-sm-10 col-md-6 col-lg-4 mx-4 mx-lg-0" size="lg"
              @click="$router.push('/service-contract')">
              <span>{{ $t('useGuidePage.cta_button.buttonText', [appName]) }}</span>
            </base-button>
          </div>
        </section>
      </div>

    </div>
  </div>
</template>
  
<script>
import { mapState } from 'vuex';
import {
  BaseButton,
  HeaderImage
} from "@/components/index";

export default {
  data() {
    return {
      appName: process.env.VUE_APP_AS_NAME,
      itemsIndex: ['login', 'customizeStoreData', 'closeStore', 'servicesAndCategories', 'registerEmployees', 'registerCustomers'],
      icons: {
        login: 'circle',
        customizeStoreData: 'location',
        closeStore: 'location',
        servicesAndCategories: 'doc',
        registerEmployees: 'id',
        registerCustomers: 'user',
      },
      imagesClasses: {
        login: 'd-flex mx-auto',
        customizeStoreData: '',
        closeStore: 'col-6',
        servicesAndCategories: '',
        registerEmployees: '',
        registerCustomers: '',
      },
      points: {
        customizeStoreData: ["businessName", "businessAddress", "intervalBetweenAppointments", "relatedCategories", "googleMapLocation", "images"],
        registerEmployees: ["name", "address", "gender", "employeeImage", "password"],
      },
      images: {
        login: ['login'],
        customizeStoreData: ['storeData'],
        closeStore: ['closeStore1', 'closeStore2'],
        servicesAndCategories: ['services-categories-1', 'services-categories-2', 'services-categories-3'],
        registerEmployees: ['employees'],
        registerCustomers: ['customers'],
      },
      stepsGuide: {
        login: ['title', 'description', 'image'],
        customizeStoreData: ['title', 'description', 'points', 'image'],
        closeStore: ['title', 'description', 'image'],
        servicesAndCategories: ['title', 'description', 'image'],
        registerEmployees: ['title', 'description', 'points', 'image'],
        registerCustomers: ['title', 'description', 'image'],
      },
      elementsWithFirstImage: ['closeStore', 'registerCustomers']
    }
  },
  components: {
    BaseButton,
    HeaderImage
  },
  computed: { ...mapState(["defaultTitle"]) },
  metaInfo() {
    return {
      title: `${this.$t('footerLink.useGuide')} - ${this.defaultTitle}`,
      meta: [
        {name: 'og:title', property: 'og:title', content: `${this.$t('footerLink.useGuide')} - ${this.defaultTitle}`},
        {name: 'og:type', property: 'og:type', content: 'website'},
        {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
        {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.businessInformation.description')},
        {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME},
        {name: 'description', content: this.$t('vueMeta.businessInformation.description')}
      ]
    }
  }
}
</script>
  
<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';

p {
  font-size: 1rem !important;
}

section {

  h2,
  h3,
  p {
    color: black !important;
    display: block;
    font-family: $font-family-base;
  }

  h2 {
    font-weight: bolder;
    font-size: 2.3rem;
    margin-bottom: 0;
  }

  h3 {
    font-weight: bolder;
    font-size: 2.2rem;
    margin-bottom: 0;
    line-height: 2.3rem;
  }

  p {
    font-weight: 400;
    font-size: 0.9rem;

    &.textIcon {
      font-size: 0.75rem;
    }
  }

  &.bg-white {
    background: white;
  }

  &.bg-gray {
    background: #F5F6FA;

  }
}

.bold {
  font-weight: bold;
}

.icon {
  width: 5.5rem;
  height: 5.5rem;
}

.mx-10 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.container-video-youtube {
  width: 90vw;
  position: relative;
  aspect-ratio: 16/9;

  left: 50%;
  transform: translate(-50%, -3.5rem);
}

.container-text {
  line-height: 1.5rem;
}

.container-index {
  line-height: 3rem;
  margin-top: 5rem;
  margin-bottom: 5rem;

  ol {
    font-size: 1rem;
    list-style-type: decimal-leading-zero;

    .itemIndex {
      color: $primary;
    }
  }
}

.title, .description {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 5px;
}

.container-icon-steps-guide {
  grid-column: 1 / 2;
  border-radius: 6px;
  display: grid;
  height: 20px;
  margin-top: 0.5rem;
  padding: 4px;
  place-items: center;
  width: 20px;
}
.container-icon-steps-guide {
  background: $primary;
}

.title-steps-guide, .description-container {
  grid-column: 2 / 3;
}

.points-list {
  padding-left: 0.5rem;
  list-style-type: '-';
  font-size: 1rem;
}

// Backgrounds
.background-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;

  &.background-closeStore {
    display: flex;
  }

  &.background-servicesAndCategories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.background-login {
  background-image: url('../../assets/img/shared/fondo1.png');
}

.background-customizeStoreData {
  background-image: url('../../assets/img/shared/fondo2.png');
}

.background-closeStore {
  background-image: url('../../assets/img/shared/fondo3.png');
}

.background-servicesAndCategories {
  background-image: url('../../assets/img/shared/fondo4V.png');
  background-size: auto;
}

.background-registerEmployees {
  background-image: url('../../assets/img/shared/fondo5.png');
}

.background-registerCustomers {
  background-image: url('../../assets/img/shared/fondo6.png');
}

// Layouts
.layout-registerCustomers,
.layout-closeStore {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: repeat(3, 1fr);

  .title {
    grid-column: 1/2;
    grid-row: 1/2
  }

  .description {
    grid-column: 1/2;
    grid-row: 3/4;

    .description-container {
      grid-column: 1 / 3;
    }
  }

  .image {
    max-width: 400px;
    margin: 0 auto;

    grid-column: 1/2;
    grid-row: 2/3;
  }
}

// MEDIA QUERIES
@media (min-width: 576px) {
  section {
    h3 {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }

  .container-video-youtube {
    width: 80vw;
  }

  .title, .description {
    grid-template-columns: 25px 1fr;
    grid-gap: 7px;
  }
  
  .container-icon-steps-guide {
    height: 25px;
    margin-left: auto;
    margin-top: -0.1rem;
    width: 25px;
  }

  // Layouts
  .layout-login {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: repeat(2, 1fr);

    .title {
      margin: auto 0 0 !important;
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .description {
      display: grid;
      grid-template-columns: repeat(2, 30px, 1fr);
      grid-auto-rows: repeat(2, 1fr);
      grid-gap: 10px;

      p {
        font-size: 1rem !important;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    }

    .title,
    .description {
      max-width: 95%;
    }

    .image {
      grid-column: 2/3;
      grid-row: 1/3;
    }

  }
  .layout-customizeStoreData {
    .image {
      margin: auto;
      max-width: 350px;
    }
  }

  .layout-registerEmployees {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: repeat(2, 1fr);

    .title {
      margin-top: auto;
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .description {
      margin: 0 auto auto !important;
      grid-column: 1/2;
      grid-row: 2/3;

      p {
        font-size: 1rem !important;
      }
    }

    .image {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }

  .layout-registerCustomers,
  .layout-closeStore {
    display: grid;
    .title {
      margin: 0 auto;
      grid-column: 1/3;
      grid-row: 1/2
    }

    .description {
      max-width: 50%;
      margin: 0 auto;
      grid-column: 1/3;
      grid-row: 3/4;
      text-align: center;
      
      p {
        font-size: 1rem !important;
      }
    }

    .image {
      max-width: 400px;
      margin: 0 auto;

      grid-column: 1/3;
      grid-row: 2/3;
    }
  }

  .layout-closeStore>.image {
    max-width: 700px;
  }
}

@media (min-width: 768px) {
  section {
    h3 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  .title, .description {
    grid-template-columns: 30px 1fr;
    grid-gap: 10px;
  }

  .container-icon-steps-guide {
    border-radius: 6px;
    height: 30px;
    display: grid;
    place-content: center;
    margin-top: 0.1rem;
    padding: 5px;
    width: 30px;
  }
  .layout-customizeStoreData {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: repeat(2, 1fr);

    .title {
      margin-top: auto;
      grid-column: 2/3;
      grid-row: 1/2;
    }

    .description {
      grid-column: 2/3;
      grid-row: 2/3;

      p {
        font-size: 1rem !important;
      }
    }

    .image {
      grid-column: 1/2;
      grid-row: 1/3;
      max-width: 350px;
      margin: auto 0!important;

      img {
        height: 100%;
      }
    }
  }
  .layout-servicesAndCategories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: repeat(3, 1fr);
    background-image: url('../../assets/img/shared/fondo4V.png') bottom right no-repeat !important;
    position: relative;

    .title {
      margin-top: auto;
      grid-column: 2/3;
      grid-row: 1/2;
    }

    .description {
      grid-column: 2/3;
      grid-row: 2/3;

      p {
        font-size: 1rem !important;
      }
    }

    .image {
      background-image: none;
      grid-column: 1 / 2;
      grid-row: 1 / 4;

      z-index: 9;

      img:not(:nth-of-type(1)) {
        display: none;
      }
    }

    .rest-image {
      grid-column: 2 / 3;
      grid-row: 3 / 4;

      img {
        width: 50%;
        height: auto;
        max-width: 50%;
        z-index: 9;
        object-fit: contain;
      }
    }

    .background-categories-horizontal {
      width: 85% !important;
      position: absolute !important;
      bottom: 16%;
      right: 7%;
      max-width: 60%;
    }
  }
}

@media(min-width: 1400px) {
  .container-video-youtube {
    max-width: 900px;
  }
}
</style>
  
<style lang="scss">
@import '../../assets/scss/white-dashboard.scss';

.header-text {
  color: white !important;
  font-family: $font-family-base;
  font-size: 2rem !important;
  font-weight: 300;
  position: absolute;
  text-align: center;
  width: 100%;
}

.header-text {
  display: grid;
  place-content: center;
  height: 100%;
}

.header-height {
  position: relative;
  width: auto !important;
  height: 90vh !important;

  img {
    width: auto !important;
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 0%;
  }
}

@media (min-width: 576px) {
  .header-text {
    font-size: 2.8rem;
  }

  .header-height {
    width: auto !important;
    height: auto !important;
  }
}
</style>